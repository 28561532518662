<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="stepName.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                       <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="stepName.service_id"
                            :options="serviceList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Step Name (En)" vid="step_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="step_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('step_name.step_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="step_name"
                              v-model="stepName.step_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Step Name (Bn)" vid="step_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="step_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('step_name.step_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="step_name_bn"
                              v-model="stepName.step_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Communication Linkage" vid="common_grid" rules='required'>
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="common_grid"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('step_name.common_grid_column') }} <span class="text-danger">*</span>
                              </template>

                              <v-select
                                  id="common_grid"
                                  v-model="stepName.common_grid"
                                  multiple
                                  :options="commonGridList"
                                  label="text"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </v-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                            </b-form-group>
                       </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { stepNameStore, stepNameUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  props: ['id'],
  mixins: [commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      stepName: {
        step_name: '',
        step_name_bn: '',
        common_grid: [],
        panel: 0,
        service_id: 0,
        org_id: 0
      },
      loading: false,
      serviceList: []
    }
  },
  created () {
    this.stepName.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getStepnameData()
      const commonGrid = JSON.parse(tmp.common_grid)
      this.stepName = Object.assign(tmp, { common_grid: commonGrid })
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    chosePanel: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Public' : 'পাবলিক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Admin' : 'এডমিন' }
      ]
      return list
    },
    commonGridList () {
      return [
        {
          text: 'Organization',
          text_bn: 'সংস্থা',
          value: 'org_id'
        },
        {
          text: 'Service',
          text_bn: 'সার্ভিস',
          value: 'service_id'
        },
        {
          text: 'Status',
          text_bn: 'অবস্থা',
          value: 'status'
        },
        {
          text: 'Application Id',
          text_bn: 'আবেদন আইডি',
          value: 'application_id'
        },
        {
          text: 'Application Date',
          text_bn: 'আবেদনের তারিখ',
          value: 'application_date'
        }
      ]
    }
  },
  watch: {
    'stepName.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    }
  },
  methods: {
     getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getStepnameData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.stepName.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${stepNameUpdate}/${this.id}`, this.stepName)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, stepNameStore, this.stepName)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
